import { patchState, withMethods } from '@ngrx/signals';
import { BNProductAttribute, BNProductImageList, BNProductShow } from '../models/product';
import { ShopGetProductCollection$Json$Params } from '../../openapi/fn/product/shop-get-product-collection-json';

export const defaultSyliusSignalStoreMethodFactory = withMethods((store) => ({
  /**
   * Betöltés alatt van a lista?
   * @param loading
   */
  setLoading: (loading: boolean) => patchState(store, {loading}),
  setError: (error: any) => patchState(store, {error}),
  setComplete: (complete: boolean) => patchState(store, {complete})
}));


export interface BNProductImageCollection {
  /**
   * @see BNProductShow['code'] | BNProductIndex['code']
   */
  id: BNProductShow['code'];
  images: BNProductImageList;
}

export interface BProductAttributeCollection {
  id: BNProductShow['code'];
  attributes: BNProductAttribute[];
}


export interface ProductCodeLoaderState {
  /**
   * Betöltés alatt lévő termékek
   */
  loadingProducts: BNProductShow['code'][],
  loadErrors: {
    [key: string]: any
  }
}

export interface SyliusEntityStoreState {
  /**
   * Betöltés folyamatban?
   */
  loading: boolean;
  /**
   * Hiba a lekérdezésnél?
   */
  error: any;
  /**
   * A teljes entity-lista?
   */
  complete: boolean;
}

export interface ProductIndexPartialState extends SyliusEntityStoreState {
  params: ShopGetProductCollection$Json$Params | null;
}
