import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { removeAllEntities, setAllEntities, setEntity, withEntities } from '@ngrx/signals/entities';

import {
  BNProductAttribute,
  BNProductIndex,
  BNProductShow,
  BNProductVariantIndex,
  SyliusImageFilterOption
} from '../models/product';
import { ShopGetProductCollection$Json$Params } from '../../openapi/fn/product/shop-get-product-collection-json';
import { deepEqual } from 'fast-equals';
import {
  BNProductImageCollection,
  BProductAttributeCollection,
  defaultSyliusSignalStoreMethodFactory,
  ProductCodeLoaderState,
  ProductIndexPartialState,
  SyliusEntityStoreState
} from './store-models';


const defaultSyliusEntityStoreState = {
  loading: false,
  error: false,
  complete: false
}

export const ProductIndexStore = signalStore(
  {providedIn: 'root'},
  withState<ProductIndexPartialState>({
    params: null,
    ...defaultSyliusEntityStoreState
  }),
  withEntities<BNProductIndex>(),
  withMethods((store) => ({
    setCollection: (params: ShopGetProductCollection$Json$Params, productCollection: BNProductIndex[]) => {
      patchState(store, setAllEntities(productCollection));
      patchState(store, {params});
    },
    setParams: (params: ShopGetProductCollection$Json$Params) => patchState(store, {params}),
    clearCollection: () => patchState(store, removeAllEntities()),
    hasCollectionWith: (params: ShopGetProductCollection$Json$Params) => deepEqual(store.params(), params)
  })),
  defaultSyliusSignalStoreMethodFactory
);

/**
 * /shop/products/variants, árakat minden extra paramétert tartalmaz, amit a fő termék nem
 */
export const ProductVariantStore = signalStore(
  {providedIn: 'root'},
  withState<SyliusEntityStoreState>({
    ...defaultSyliusEntityStoreState
  }),
  withEntities<BNProductVariantIndex>(),
  withMethods((store) => ({
    setCollection: (productCollection: BNProductVariantIndex[]) =>
      patchState(store, setAllEntities(productCollection)),
    clearCollection: () => patchState(store, removeAllEntities()),
    getByProductPath: (productCode: string) => store.entities().filter((variant) => variant.product === productCode)
  })),
  defaultSyliusSignalStoreMethodFactory
);

/**
 * /shop/products/
 * A termék leírása, képei, stb.
 */
export const ProductShowStore = signalStore(
  {providedIn: 'root'},

  withState<ProductCodeLoaderState>({
    loadingProducts: [],
    loadErrors: {}
  }),
  withEntities<BNProductShow>(),
  withMethods((store) => ({
      addProduct: (product: BNProductShow) => patchState(store, setEntity(product)),
      getByCode: (code: string) => store.entities().find((product) => product.code === code),
      setLoadError: (productCode: string, error: any) =>
        patchState(store, {
          loadErrors: {
            ...store.loadErrors,
            [productCode]: error
          },
          loadingProducts: store.loadingProducts().filter((loading) => loading !== productCode)
        }),
      setLoading: (productCode: string, isLoading = true) => {
        const loadingProducts = isLoading ? [...store.loadingProducts(), productCode] : store.loadingProducts().filter((loading) => loading !== productCode);
        patchState(store, {loadingProducts});
      },
      isLoading: (productCode: string) => store.loadingProducts().includes(productCode)
    })
  )
);

/**
 * A termék képeinek tárolása, cache-elése
 */
export const ProductImageCollectionStore = signalStore(
  {providedIn: 'root'},
  withState<ProductCodeLoaderState>({
    loadingProducts: [],
    loadErrors: {}
  }),
  withEntities<BNProductImageCollection>(),
  withMethods((store) => ({
      getByCode: (code: string): BNProductImageCollection => store.entityMap()[code],
      getByCodeAndFilter: (productCode: string, filter: SyliusImageFilterOption): string[] | undefined =>
        store.entityMap()[productCode]?.images[filter],

      setByFilterAndCode: (productCode: string, filter: SyliusImageFilterOption, images: string[]) => {
        const collection = store.entityMap()[productCode] || {id: productCode, images: {}};
        collection.images[filter] = images;
        patchState(store, setEntity(collection));
      },
      setLoadError: (productCode: string, filter: SyliusImageFilterOption, error: any) => {
        patchState(store, {
          loadErrors: {
            ...store.loadErrors,
            [productCode + filter]: error
          }
        })
      },
      setLoading: (productCode: string, filter: SyliusImageFilterOption) => {
        const loadingProducts = [...store.loadingProducts(), productCode + filter];
        patchState(store, {loadingProducts});
      },
      isLoading: (productCode: string, filter: SyliusImageFilterOption) => store.loadingProducts().includes(productCode + filter),
      clearLoading: (productCode: string, filter: SyliusImageFilterOption) => {
        patchState(store, {
            loadingProducts: store.loadingProducts().filter(
              (loading) => loading !== productCode + filter)
          }
        );
      }
    })
  )
);

export const ProductAttributeCollectionStore = signalStore(
  {providedIn: 'root'},
  withState<SyliusEntityStoreState>({
    ...defaultSyliusEntityStoreState
  }),
  withEntities<BProductAttributeCollection>(),
  withMethods((store) => ({
    setByCode: (productCode: string, attributes: BNProductAttribute[]) => {
      patchState(store, setEntity({id: productCode, attributes}));
    },
    getByCode: (productCode: string) => store.entityMap()[productCode] ? store.entityMap()[productCode]['attributes'] : [],
    clearCollection: () => patchState(store, removeAllEntities())
  })),
  defaultSyliusSignalStoreMethodFactory
);
