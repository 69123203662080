import { DEFAULT_CURRENCY_CODE, inject, InjectionToken, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

export const DEFAULT_CURRENCY_DISPLAY: InjectionToken<
  | 'code'
  | 'symbol'
  | 'symbol-narrow'
  | string
  | boolean> = new InjectionToken<"code" | "symbol" | "symbol-narrow" | string | boolean>("DEFAULT_CURRENCY_DISPLAY");
export const DEFAULT_CURRENCY_DIGITS_INFO: InjectionToken<string> = new InjectionToken<string>("DEFAULT_CURRENCY_DIGITS_INFO");

@Pipe({
  name: 'currencyBn',
  standalone: true
})
export class CurrencyBnPipe implements PipeTransform {
  private _locale: string = inject(LOCALE_ID);
  private _defaultCurrencyCode: string = inject(DEFAULT_CURRENCY_CODE);
  private _defaultCurrencyDisplay = inject(DEFAULT_CURRENCY_DISPLAY);
  private _defaultCurrencyDigitsInfo = inject(DEFAULT_CURRENCY_DIGITS_INFO);

  private _currencyPipe: CurrencyPipe = new CurrencyPipe(this._locale, this._defaultCurrencyCode);

  transform(
    value: number | string | null | undefined,
    currencyCode: string = this._defaultCurrencyCode,
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = this._defaultCurrencyDisplay,
    digitsInfo: string = this._defaultCurrencyDigitsInfo,
    locale: string = this._locale
  ): string | null {
    return this._currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
  }
}

