import { computed, Injectable, signal } from '@angular/core';

interface Messages {
  id: number;
  /**
   * Üzenet maga
   */
  content: string;
  /**
   * Üzenet szintje
   */
  level: 'info' | 'warning' | 'error';
  /**
   * Üzenet forrása (ha bent van már, vagy volt már akkor ne jelenítsük meg újra)
   */
  source: string;
  visible: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class MessageFlashService {
  private messageContainer = signal<Messages[]>([]);
  private visibleMessages = computed(() => this.messageContainer().filter((m) => m.visible));

  show(message: string, source: string, level: Messages['level'] = 'info'): number {
    const prevMessage = this.messageContainer().find((m) => m.content === message && m.source === source);

    if (prevMessage) {
      return prevMessage.id;
    }

    const id = this.messageContainer().length;
    this.messageContainer.set([
      ...this.messageContainer(),
      {
        id: id,
        content: message, source, level, visible: true
      }
    ]);
    return id;
  }

  hide(id: number) {
    this.messageContainer.set(this.messageContainer().map((m) => m.id === id ? {...m, visible: false} : m));
  }

  getMessages() {
    return this.messageContainer;
  }

}
